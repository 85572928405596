// Tailwind CSS
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: var(--color-nb-green-forest);
    --color-secondary: var(--color-nb-green-primary);
    --color-success: var(--color-nb-green-3);
    --color-alert: var(--color-nb-orange-2);
    --color-warn: var(--color-nb-red-2);
    --color-info: var(--color-nb-blue-2);
    --color-disabled: var(--color-nb-gray-1);

    // Nobina colors: https://raccoon.nobina.com/nobinasvarumarke.4.6dad6aaf1763a4f02b49fea.html
    --color-nb-green-primary: 57 169 53;
    --color-nb-green-forest: 4 61 12;
    --color-nb-green-1: 196 219 155;
    --color-nb-green-2: 159 202 121;
    --color-nb-green-3: 98 181 90;
    --color-nb-green-4: 0 142 56;
    --color-nb-green-5: 0 111 50;
    --color-nb-green-6: 18 84 40;

    --color-nb-orange-1: 253 197 67;
    --color-nb-orange-2: 242 138 61;

    --color-nb-red-1: 235 93 72;
    --color-nb-red-2: 215 53 52;

    --color-nb-blue-1: 177 214 228;
    --color-nb-blue-2: 103 183 209;
    --color-nb-blue-3: 45 129 177;
    --color-nb-blue-4: 0 80 140;

    --color-nb-pink-1: 248 201 223;
    --color-nb-pink-2: 242 159 197;
    --color-nb-pink-3: 203 93 159;
    --color-nb-pink-4: 185 52 139;

    --color-nb-gray-1: 200 200 200;
  }
}
