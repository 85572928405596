// Longer select dropdown <mat-select/>
.mdc-menu-surface.mat-mdc-select-panel {
  max-height: 500px !important;
}

// Dialog titel set to 20px
.mat-mdc-dialog-title {
  font-size: 20px !important;
}

// Input background set to white
.mat-mdc-text-field-wrapper {
  //background-color: white !important;
}

// Input line-height fix (broken in dialogs)
.mat-mdc-form-field-infix {
  line-height: 1.5;
}

// Table rows hover color and active color
.mat-mdc-row {
  &:hover .mat-mdc-cell {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.active .mat-mdc-cell {
    background-color: rgb(var(--color-secondary) / .12);
  }

  &.disabled .mat-mdc-cell {
    color: rgb(var(--color-disabled));
  }
}

// Active background-color for link button
.mdc-button.active {
  background-color: rgba(255, 255, 255, 0.08) !important;
}

// https://stackoverflow.com/questions/74500132/angular-15-material-bug-input-on-focus-when-using-tailwindcss
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.cdk-global-scrollblock {
  //overflow-y: hidden !important;
}

.mdc-icon-button {
  .mat-badge-active {
    font-size: 18px !important;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
  }
}

.mat-mdc-form-field-error-wrapper {
  z-index: 50;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: white !important;
}

.server-notification {
  .mdc-dialog__surface {
    @apply shadow-md border #{!important};
    // Combine multiple classes
  }
}

.mdc-tooltip__surface {
  font-size: 14px !important;
}

.mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-outlined-button,  .mat-mdc-unelevated-button {
  height: 36px !important;
  border-width: 2px !important;
}

.mat-mdc-outlined-button.mat-warn{
  border-color: var(--color-nb-red-1) !important;
}

.mat-mdc-outlined-button.mat-primary{
  border-color: var(--color-nb-green-primary) !important;
}
