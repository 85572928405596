// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import './material-nobina-palette';

// Define the palettes for your theme using the palettes available in nobina-palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$nobina-primary: mat.define-palette($primary-palette, 900);
$nobina-accent: mat.define-palette($secondary-palette, 500, 400, 600);

// The warn palette is optional (defaults to red).
$nobina-warn: mat.define-palette($red-palette, 700);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nobina-theme: mat.define-light-theme((
  density: -3,
  color: (
    primary: $nobina-primary,
    accent: $nobina-accent,
    warn: $nobina-warn,
  ),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nobina-theme);

/* You can add global styles to this file, and also import other style files */
@import "./tailwind-theme.scss";

@import "./material-custom.scss";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

//Environment color classes
:root {
  --color-nb-blue-3-hex: #2D81B1;
  --color-nb-green-4-hex: #008E38;
  --color-nb-red-2-hex: #d73534;
  --color-nb-pink-4-hex: #b9348b;
  --color-nb-green-6-hex: #043d0b;
  --color-nb-light-gray: #E5E7EB;
  --color-nb-orange-1: #FDC543;
}

.env-bg-ft {
  background-color: var(--color-nb-blue-3-hex) !important;
}

.env-bg-at {
  background-color: var(--color-nb-green-4-hex) !important;
}

.env-bg-st {
  background-color: var(--color-nb-red-2-hex) !important;
}

.env-bg-local {
  background-color: var(--color-nb-pink-4-hex) !important;
}

.env-bg-production {
  background-color: var(--color-nb-green-6-hex) !important;
}

.transparent-buttons {
  color: #fff;

  & .mat-mdc-unelevated-button:not(:disabled) {
    background-color: transparent;
  }
}

.link-active {
  @apply bg-white bg-opacity-5 #{!important};
}

.mat-drawer-inner-container {
  overflow-x: hidden !important;
}
